import { useEffect, useMemo, useState } from 'react'

// material-ui
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'

import {
  IconArchive,
  IconCopy,
  IconDatabase,
  IconFileExport,
  IconLayout,
  IconLayoutGrid,
  IconPhotoPlus,
  IconPhoto,
  IconPlus,
  IconRecycle
} from '@tabler/icons'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import humanizeDuration from 'humanize-duration'
import { truncate } from 'lodash'
import { useSelector } from 'react-redux'
import useAuth from 'hooks/useAuth'
import { FileDownload } from '@mui/icons-material'
import useLang from 'hooks/useLang'

// ==============================|| TABLE - STICKY HEADER ||============================== //

export default function SimpleTable (props) {
  const { lang } = useLang()
  const { rows, columns, onCopy, permission } = props
  const theme = useTheme()
  const [view, setView] = useState('active')
  const [data, setData] = useState(rows)
  const { hasPermission } = useAuth()
  const bookingSchemes = useSelector(
    ({ bookingStore }) => bookingStore.bookingSchemes
  )

  const days = [
    { name: 'Sunday', abbr: lang == 'Arabic' ? 'الأحد' : 'Sun' },
    { name: 'Monday', abbr: lang == 'Arabic' ? 'الاثنين' : 'Mon' },
    { name: 'Tuesday', abbr: lang == 'Arabic' ? 'الثلاثاء' : 'Tue' },
    { name: 'Wednesday', abbr: lang == 'Arabic' ? 'الأربعاء' : 'Wed' },
    { name: 'Thursday', abbr: lang == 'Arabic' ? 'الخميس' : 'Thu' },
    { name: 'Friday', abbr: lang == 'Arabic' ? 'الجمعة' : 'Fri' },
    { name: 'Saturday', abbr: lang == 'Arabic' ? 'السبت' : 'Sat' }
  ]

  //check is MERCHANT or has PERMISSION
  const hasWritePerm = useMemo(
    () => !permission || hasPermission(permission, 'write'),
    [permission, hasPermission]
  )

  useEffect(() => {
    if (view == 'all') {
      setData(rows)
    } else {
      if (view == 'active') {
        setData(rows.filter(r => !r.archived_at))
      } else if (view == 'archived') {
        const archived = rows.filter(r => r.archived_at)
        setData(
          archived.length < 1 ? rows.filter(r => !r.archived_at) : archived
        )
      }
    }
  }, [view, rows])

  return (
    <MainCard
      content={false}
      title={props?.archive || props?.hasLayout ? '' : props?.title}
      secondary={
        !props?.plain &&
        !props?.hasLayout &&
        !props?.archive && (
          <Grid display={'flex'}>
            {props.hasLayout && (
              <Grid sx={{ pr: '25px' }}>
                <Tooltip
                  title={lang == 'Arabic' ? 'عرض التخطيط' : 'Layout view'}
                >
                  <IconButton
                    color='primary'
                    onClick={() => props.setView('layout')}
                  >
                    <IconLayout />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {props.onAdd &&
            hasWritePerm &&
            (!props?.loaded || props?.loaded == 'true') &&
            (!props?.max ||
              !props?.rowsCount ||
              props.rowsCount < props?.max) ? (
              <Button
                style={props.disableAdd ? { cursor: 'not-allowed' } : {}}
                onClick={props.disableAdd ? () => {} : props.onAdd}
                variant='contained'
                color='primary'
              >
                {lang == 'Arabic' ? 'إضافة جديد' : 'Add new'}
              </Button>
            ) : (
              <Grid></Grid>
            )}
          </Grid>
        )
      }
    >
      {(props?.hasLayout || props?.archive) && (
        <>
          <Grid
            alignItems='center'
            container
            justifyContent='space-between'
            p={'20px'}
            px={'24px'}
          >
            <Typography
              variant='h3'
              fontWeight='500'
              fontSize='1.125rem'
              lineHeight={'1.334'}
            >
              {props?.title}
            </Typography>
            {rows.filter(r => r.archived_at)?.length > 0 && (
              <ButtonGroup
                variant='outlined'
                aria-label='outlined button group'
                style={{
                  marginRight: 15,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button
                  disableElevation
                  variant={view == 'active' ? 'contained' : 'outlined'}
                  onClick={() => setView('active')}
                >
                  {lang == 'Arabic' ? 'النشطة' : 'Active'}
                </Button>
                <Button
                  disableElevation
                  variant={view == 'archived' ? 'contained' : 'outlined'}
                  onClick={() => setView('archived')}
                >
                  {lang == 'Arabic' ? 'المؤرشفة' : 'Archived'}
                </Button>
                <Button
                  disableElevation
                  variant={view == 'all' ? 'contained' : 'outlined'}
                  onClick={() => setView('all')}
                >
                  {lang == 'Arabic' ? 'الكل' : 'All'}
                </Button>
              </ButtonGroup>
            )}
            <Grid display={'flex'}>
              {props.hasLayout && (
                <Grid sx={{ pr: '25px' }}>
                  <Tooltip
                    title={lang == 'Arabic' ? 'عرض التخطيط' : 'Layout view'}
                  >
                    <IconButton
                      color='primary'
                      onClick={() => props.setView('layout')}
                    >
                      <IconLayout />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {props.onAdd &&
              hasWritePerm &&
              (!props?.loaded || props?.loaded == 'true') &&
              (!props?.max ||
                !props?.rowsCount ||
                props.rowsCount < props?.max) ? (
                <Button
                  style={props.disableAdd ? { cursor: 'not-allowed' } : {}}
                  onClick={props.disableAdd ? () => {} : props.onAdd}
                  variant='contained'
                  color='primary'
                >
                  {lang == 'Arabic' ? 'إضافة جديد' : 'Add new'}
                </Button>
              ) : (
                <Grid></Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {/* table */}
      <TableContainer>
        <Table sx={{ minWidth: 350 }} aria-label={props?.title}>
          <TableHead>
            <TableRow>
              {columns.map(
                column =>
                  column?.id && (
                    <TableCell
                      //   sx={{ py: 3 }}
                      key={column?.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        ...(column.isAmount
                          ? {
                              color: theme.palette.primary.main
                            }
                          : column?.isGreen
                          ? {
                              color: theme.palette.success.main
                            }
                          : {})
                      }}
                    >
                      {column.label}
                    </TableCell>
                  )
              )}
              {!props?.plain && hasWritePerm && (
                <TableCell align='center' sx={{ pr: 3 }}>
                  {lang == 'Arabic' ? 'الإجراء' : 'Action'}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow hover key={row.id_code}>
                {columns.map(column => {
                  let value = row[column?.id]
                  if (typeof value === 'string') {
                    value =
                      (column?.id != 'service_time' &&
                        column?.id != 'booked_time' &&
                        column?.id?.toString().indexOf('_time') > -1) ||
                      column?.id?.toString() == 'time'
                        ? moment('2022-02-01 ' + value).format('hh:mm A')
                        : value
                  }
                  if (column?.id == 'other_conditions' && row.weekdays) {
                    value = (
                      <div>
                        <div>
                          {row.weekdays?.length > 0
                            ? lang == 'Arabic'
                              ? row.weekdays
                                  .map(
                                    day => days.find(d => d.name == day).abbr
                                  )
                                  .join(lang == 'Arabic' ? '، ' : ', ')
                              : row.weekdays?.join(
                                  lang == 'Arabic' ? '، ' : ', '
                                )
                            : ''}{' '}
                        </div>
                        <div>{row?.booking_resource}</div>
                        <div>{row?.booking_service}</div>
                        <div>
                          {row?.code
                            ? (lang == 'Arabic'
                                ? 'كود الخصم: '
                                : 'Dicount code: ') + row?.code
                            : ''}
                        </div>
                      </div>
                    )
                  }
                  if (column?.id == 'service_interval_time') {
                    value = humanizeDuration(value * 1000 * 60, {
                      language: lang == 'Arabic' ? 'ar' : 'en'
                    })
                  }
                  if (column?.id == 'notes' || column?.id == 'booking_notes') {
                    value = truncate(value, 100)
                  }
                  if (column?.id?.toString().indexOf('_date') > -1 && !value) {
                    value =
                      lang == 'Arabic' ? 'الأوقات العادية' : 'Normal times'
                  }
                  if (column?.id == 'booking_resource' && !value) {
                    value =
                      lang == 'Arabic'
                        ? `الكل ${bookingSchemes.resource_pl}`
                        : `All ${bookingSchemes.resource_pl}`
                  }
                  if (column?.id == 'booking_service' && !value) {
                    value =
                      lang == 'Arabic'
                        ? `الكل ${bookingSchemes.service_pl}`
                        : `All ${bookingSchemes.service_pl}`
                  }

                  return (
                    column?.id && (
                      <TableCell
                        key={column?.id}
                        align={column.align}
                        style={
                          column.isAmount
                            ? {
                                color: theme.palette.primary.main
                              }
                            : column?.isGreen
                            ? {
                                color: theme.palette.success.main
                              }
                            : {}
                        }
                        sx={
                          row.entry_expired
                            ? { color: theme.palette.error.main }
                            : {}
                        }
                      >
                        {column.format && typeof value === 'object' ? (
                          <div>
                            {value?.length == 0
                              ? lang == 'Arabic'
                                ? 'يومي'
                                : 'Everyday'
                              : lang == 'Arabic'
                              ? value
                                  .map(
                                    day => days.find(d => d.name == day).abbr
                                  )
                                  .join(lang == 'Arabic' ? '، ' : ', ')
                              : column.format(value)}
                          </div>
                        ) : (
                          value
                        )}
                      </TableCell>
                    )
                  )
                })}
                {(!props?.plain || props.onView) && (
                  <TableCell
                    align='center'
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    {hasWritePerm && (
                      <Stack
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                      >
                        {onCopy &&
                          (!props?.max ||
                            !props?.rowsCount ||
                            props.rowsCount < props?.max) && (
                            <Tooltip
                              title={lang == 'Arabic' ? 'نسخ' : 'Duplicate'}
                            >
                              <IconButton
                                color='primary'
                                size='large'
                                style={
                                  props.disableAdd
                                    ? { cursor: 'not-allowed' }
                                    : {}
                                }
                                onClick={() =>
                                  props.disableAdd ? () => {} : onCopy(row)
                                }
                              >
                                <IconCopy />
                              </IconButton>
                            </Tooltip>
                          )}
                        {props.onPhoto && (
                          <Tooltip
                            title={lang == 'Arabic' ? 'الصورة' : 'Photo'}
                          >
                            <IconButton
                              color='primary'
                              size='large'
                              onClick={() => props.onPhoto(row)}
                            >
                              {row.image ? <IconPhoto /> : <IconPhotoPlus />}
                            </IconButton>
                          </Tooltip>
                        )}

                        {props.onView && (
                          <Tooltip title={lang == 'Arabic' ? 'عرض' : 'View'}>
                            <IconButton
                              color='primary'
                              size='large'
                              onClick={() => props.onView(row)}
                            >
                              <EyeOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {props.onEdit && (
                          <Tooltip title={lang == 'Arabic' ? 'تعديل' : 'Edit'}>
                            <IconButton
                              color='primary'
                              size='large'
                              onClick={() => props.onEdit(row)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {props.onPDF && (
                          <Tooltip
                            title={
                              lang == 'Arabic'
                                ? 'تحميل الملخص'
                                : 'Download summary'
                            }
                          >
                            <IconButton
                              color='primary'
                              size='large'
                              onClick={() => props.onPDF(row)}
                            >
                              <FileDownload />
                            </IconButton>
                          </Tooltip>
                        )}

                        {props.onExport && (
                          <Tooltip
                            title={
                              lang == 'Arabic'
                                ? 'تحميل الحجوزات'
                                : 'Download bookings'
                            }
                          >
                            <IconButton
                              color='primary'
                              size='large'
                              onClick={() => props.onExport(row)}
                            >
                              <IconFileExport />
                            </IconButton>
                          </Tooltip>
                        )}
                        {props.onDelete && (
                          <Tooltip
                            title={
                              props?.archive
                                ? row.archived_at
                                  ? lang == 'Arabic'
                                    ? 'إلغاء الأرشفة'
                                    : 'Unarchive'
                                  : lang == 'Arabic'
                                  ? 'أرشفة'
                                  : 'Archive'
                                : lang == 'Arabic'
                                ? 'حذف'
                                : 'Delete'
                            }
                          >
                            <IconButton
                              // color='error'
                              size='large'
                              onClick={() => props.onDelete(row)}
                            >
                              {props?.archive ? (
                                <>
                                  {row.archived_at ? (
                                    <IconRecycle
                                      color={theme.palette.success.main}
                                    />
                                  ) : (
                                    <IconArchive
                                      color={theme.palette.error.main}
                                    />
                                  )}
                                </>
                              ) : (
                                <DeleteOutlineOutlinedIcon
                                  color={theme.palette.error.main}
                                  htmlColor={theme.palette.error.main}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {props?.paginated && (
          <TablePagination
            labelRowsPerPage={
              lang == 'Arabic' ? 'صفوف في الصفحة:' : 'Rows per page'
            }
            labelDisplayedRows={({ from, to, count }) =>
              from + ' - ' + to + (lang == 'Arabic' ? ' من ' : ' of ') + count
            }
            rowsPerPageOptions={[5, 10, 25, 50]}
            component='div'
            count={props.rowsCount}
            rowsPerPage={props.rowsPerPage}
            page={props.current_page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        )}
      </TableContainer>

      {/* table pagination */}
    </MainCard>
  )
}

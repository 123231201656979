// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
  IconDetails,
  IconCalendarTime,
  IconMessage,
  IconTools,
  IconSettings,
  IconDashboard,
  IconReport,
  IconCalendarEvent
} from '@tabler/icons'

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const merchant = {
  id: 'merchant-menu',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      title_ar: 'اللوحة العامة',
      type: 'item',
      url: '/dashboard',
      icon: IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'chats',
      title: 'Chats',
      title_ar: 'المحادثات',
      type: 'item',
      url: '/chats',
      icon: IconMessage,
      breadcrumbs: false
    },
    {
      id: 'bookings',
      title: 'Bookings',
      title_ar: 'الحجوزات',
      type: 'item',
      url: '/bookings',
      icon: IconCalendarEvent,
      breadcrumbs: false
    },
    {
      id: 'booking_setup',
      title: 'Booking setup',
      title_ar: 'إعداد الحجز',
      breadcrumbs: false,
      // caption: 'basic-caption' ,
      type: 'collapse',
      url: '/',
      icon: IconTools,
      children: [
        {
          id: 'bookingSlots',
          title: 'Booking slots',
          title_ar: 'فترات الحجز',
          type: 'item',
          url: '/booking-slots',
          // icon: IconCalendarTime,
          breadcrumbs: false
        },
        {
          id: 'noBookingSlots',
          title: 'No-booking slots',
          title_ar: 'فترات عدم الحجز',
          type: 'item',
          url: '/no-booking-slots',
          breadcrumbs: false
        },
        {
          id: 'bookingDiscounts',
          title: 'Booking discounts',
          title_ar: 'خصومات الحجز',
          type: 'item',
          url: '/booking-discounts',
          breadcrumbs: false
        },
        {
          id: 'bookingResources',
          title: 'Booking resources',
          title_ar: 'المحجوزات',
          type: 'item',
          url: '/booking-rcs',
          breadcrumbs: false
        },
        {
          id: 'bookingServices',
          title: 'Booking services',
          title_ar: 'الخدمات',
          type: 'item',
          url: '/booking-scs',
          breadcrumbs: false
        }
      ]
    },

    {
      id: 'timeSlots',
      title: 'Time Slots',
      title_ar: 'الأوقات',
      type: 'item',
      url: '/time-slots',
      icon: IconCalendarTime,
      breadcrumbs: false
    },
    {
      id: 'details',
      title: 'Details',
      title_ar: 'التفاصيل',
      type: 'item',
      url: '/details',
      icon: IconDetails,
      breadcrumbs: false
    },
    {
      id: 'bookingReports',
      title: 'Reports',
      title_ar: 'التقارير',
      type: 'item',
      url: '/booking-reports',
      icon: IconReport,
      breadcrumbs: false
    },
    {
      id: 'settings',
      title: 'Settings',
      title_ar: 'الإعدادات',
      type: 'item',
      url: '/settings',
      icon: IconSettings,
      breadcrumbs: false
    }
  ]
}

export default merchant
